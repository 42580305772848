<template>
  <a-page-header @back="goBack" title="TIPO DE DOCUMENTO" :breadcrumb="{ routes }" class="px-0" />

  <div class="flex mb-4">
    <!--------------- Acciones--------------->
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Acciones</legend>

      <button @click="handleAdd" class="text-center px-3">
        <div class="text-3xl text-green-500">
          <FileAddOutlined />
        </div>
        <p>Nuevo</p>
      </button>

      <button @click="handleEdit" class="text-center px-3" :disabled="isDisabled.edit">
        <div
          class="text-3xl"
          :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
        >
          <FormOutlined />
        </div>
        <p>Editar</p>
      </button>

      <button @click="handleDelete" class="text-center px-3" :disabled="isDisabled.deleteAndChange">
        <div
          class="text-3xl"
          :class="[
            isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-red-400'
          ]"
        >
          <DeleteOutlined />
        </div>
        <p>Eliminar</p>
      </button>

      <button
        @click="handleChangeState"
        class="text-center px-3"
        :disabled="isDisabled.deleteAndChange"
      >
        <div
          class="text-3xl"
          :class="[
            isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400'
          ]"
        >
          <IssuesCloseOutlined />
        </div>
        <p>Cambia estado</p>
      </button>
    </fieldset>

    <!--------------- Filtros--------------->
    <fieldset class="border border-solid border-gray-300 p-3 px-8">
      <legend class="text-sm text-center">Filtros</legend>
      <tipo-documento-filtros @fetchTipoDocumentos="fetchTipoDocumentos" />
    </fieldset>
  </div>

  <!--------------- Tabla --------------->
  <a-table
    :row-selection="rowSelection"
    :columns="columns"
    :data-source="data"
    size="small"
    :pagination="false"
    :custom-row="customRow"
    rowKey="idtipodocumento"
    :loading="isLoading"
    :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
  >
    <template #rangepages="{ index }">
      <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
    </template>

    <template #activo="{ text: activo }">
      <span>
        <a-tag :color="activo === '1' ? 'green' : 'volcano'">
          {{ activo === "1" ? "Activado" : "Desactivado" }}
        </a-tag>
      </span>
    </template>
  </a-table>

  <footer class="flex items-center justify-between pb-6 pt-5 border-t-2">
    <!-- show-size-changer -> para agregar el @limit (numero de datos a traer) -->
    <div class="flex">
      <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />
      <!-- @showSizeChange="onShowSizeChange" -->

      <button class="ml-4" @click="fetchTipoDocumentos">
        <ReloadOutlined />
      </button>
    </div>

    <p>Total de registros: {{ totalDocs }}</p>
  </footer>

  <!--------------- Modal--------------->
  <tipo-documento-add-edit
    v-if="isVisible"
    :isVisible="isVisible"
    :tipodocumento="tipodocumento"
    @closeModal="closeModal"
    @fetchTipoDocumentos="fetchTipoDocumentos"
  />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode } from "vue";
import { useStore } from "vuex";
import { columns } from "./utilsTipoDocumento";
import router from "@/router";
import TipoDocumentoFiltros from "./TipoDocumentoFiltros.vue";
import TipoDocumentoAddEdit from "./TipoDocumentoAddEdit.vue";
import TipoDocumentoApi from "@/api/tipo_documento";
import { Modal, notification } from "ant-design-vue";
import {
  FileAddOutlined,
  FormOutlined,
  DeleteOutlined,
  ReloadOutlined,
  IssuesCloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons-vue";

// TODO: EXPORTAR INFORMACIÓN DE PARAMETROS

export default {
  name: "TipoDocumento",
  components: {
    TipoDocumentoFiltros,
    FormOutlined,
    DeleteOutlined,
    ReloadOutlined,
    FileAddOutlined,
    TipoDocumentoAddEdit,
    IssuesCloseOutlined
  },
  setup() {
    const store = useStore();
    const pageSize = ref(10);
    const curretPage = ref(1);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const data = ref([]);
    const isLoading = ref(false);
    const tipodocumento = ref(null);
    const state = reactive({
      selectedRowKeys: [],
      rowActiveStatus: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    // ------- onBack -------
    const goBack = () => router.back();

    // ------- breadcrumbs --------
    const routes = [
      {
        path: "app",
        breadcrumbName: "Maestros"
      },
      {
        path: "/tipo-documento",
        breadcrumbName: "Tipo documento"
      }
    ];

    // ------- trae datos de la api --------
    const fetchTipoDocumentos = () => {
      const filter = store.getters["tipodocumento/filter"];
      const search = store.getters["tipodocumento/search"];
      const currentPage = toRaw(curretPage.value);

      isLoading.value = true;
      TipoDocumentoApi.getAll({ currentPage, filter, search })
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      fetchTipoDocumentos();
    });

    // -------- se ejecuta cuando selecciona una fila --------
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          console.log(selectedRowKeys);

          state.selectedRowKeys = selectedRowKeys;
        }
      };
    });

    /**
     * guarda el id en seleccionado en el array selectedRowKeys
     * guarda el estado activo en el array rowActiveStatus
     */
    const selectRow = (record) => {
      const selectedRowKeys = [...state.selectedRowKeys];
      const rowActiveStatus = [...state.rowActiveStatus];

      if (selectedRowKeys.indexOf(record.idtipodocumento) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.idtipodocumento), 1);
        rowActiveStatus.splice(rowActiveStatus.indexOf(record.activo), 1);
      } else {
        selectedRowKeys.push(record.idtipodocumento);
        rowActiveStatus.push(record.activo);
      }

      state.selectedRowKeys = selectedRowKeys;
      state.rowActiveStatus = rowActiveStatus;
    };

    const customRow = (record) => {
      return {
        onClick: () => {
          selectRow(record);
        }
      };
    };

    // -------- trae datos al cambiar de pagina --------

    watch(curretPage, () => {
      fetchTipoDocumentos();
      clearSelectedArrays();
    });

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    // const reloadFetchTipoDocumentos = () => {
    //   fetchTipoDocumentos(curretPage.value);
    // };

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- acciones -------
    const handleAdd = () => {
      tipodocumento.value = {
        denominacion: "",
        abreviatura: ""
      };
      showModal();
    };

    const handleEdit = () => {
      const [idtipodocumento] = state.selectedRowKeys;

      if (!state.selectedRowKeys.length || state.selectedRowKeys.length !== 1) {
        showInvalidOperation(
          "Tienes que seleccionar necesariamente un (1) item para realizar la operación."
        );
      } else {
        tipodocumento.value = data.value.find((p) => p.idtipodocumento === idtipodocumento);
        showModal();
      }
    };

    /**
     * params {String} description
     */
    const showInvalidOperation = (description) => {
      const meta =
        "Tienes que seleccionar al menos una fila de la tabla para realizar la operación.";

      notification.error({
        message: "Operación inválida",
        description: description || meta
      });
    };

    const handleChangeState = () => {
      if (!state.selectedRowKeys.length) {
        showInvalidOperation();
      } else {
        Modal.confirm({
          title: () => "¿Estás seguro de cambiar el estado?",
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => `Se cambiará el estado de ${state.selectedRowKeys.length} registros`,
          okText: () => "Sí, cambiar",
          okType: "danger",
          cancelText: () => "Cancelar",

          onOk() {
            const payload = {
              ids: toRaw(state.selectedRowKeys),
              activos: toRaw(state.rowActiveStatus).map((r) => (r === "1" ? "0" : "1"))
            };

            TipoDocumentoApi.changeStateMany(payload)
              .then(() => {
                // reloadFetchTipoDocumentos();
                fetchTipoDocumentos();
                clearSelectedArrays();
              })
              .catch((err) => console.log(err));
          }
        });
      }
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
      state.rowActiveStatus = [];
    };

    const handleDelete = () => {
      if (!state.selectedRowKeys.length) {
        showInvalidOperation();
      } else {
        Modal.confirm({
          title: () => "¿Estás seguro de eliminar?",
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => `Se eliminarán ${state.selectedRowKeys.length} registros`,
          okText: () => "Sí, eliminar",
          okType: "danger",
          cancelText: () => "Cancelar",

          onOk() {
            TipoDocumentoApi.deleteMany(toRaw(state.selectedRowKeys))
              .then((res) => {
                // reloadFetchTipoDocumentos();
                fetchTipoDocumentos();
                clearSelectedArrays();
                console.log(res);
              })
              .catch((err) => console.log(err));
          }
        });
      }
    };

    return {
      routes,
      isLoading,
      data,
      columns,
      customRow,
      rowSelection,
      handleAdd,
      handleEdit,
      handleDelete,
      handleChangeState,
      pageSize,
      curretPage,
      totalDocs,
      isVisible,
      closeModal,
      fetchTipoDocumentos,
      tipodocumento,
      goBack,
      isDisabled
      // filter,
      // search
    };
  }
};
</script>

<style></style>
