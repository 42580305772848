<template>
  <a-modal
    :visible="true"
    title="Nuevo tipo de documento"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm"
    okText="Guardar"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" v-bind="layout">
      <a-form-item label="abreviatura" name="abreviatura">
        <a-input v-model:value="formState.abreviatura" placeholder="ejm. DNI" />
      </a-form-item>
      <a-form-item label="Denominación" name="denominacion">
        <a-textarea
          v-model:value="formState.denominacion"
          placeholder="ejm. Documento Nacional de Identidad"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import TipoDocumentoApi from "@/api/tipo_documento";

export default {
  name: "TipoDocumentoAddEdit",
  props: {
    tipodocumento: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchTipoDocumentos"],
  setup(props, { emit }) {
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const tipodocumento = props.tipodocumento; // se define porque tiene que ser unico en editar
    const isEdit = !!props.tipodocumento.idtipodocumento;
    const denominacion = props.tipodocumento.denominacion;

    const formState = reactive(tipodocumento);

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";
          try {
            if (isEdit) {
              const isUnique = denominacion !== formState.denominacion;
              const idparametro = toRaw(formState)?.idparametro;

              response = await TipoDocumentoApi.editOne(payload, idparametro, isUnique);
              msgNotificacion = "El tipo de documento ha sido modificado correctamente.";
            } else {
              response = await TipoDocumentoApi.addOne(payload);
              msgNotificacion = "El tipo de documento ha sido creado satisfactoriamente.";
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchTipoDocumentos");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("fetchTipoDocumentos");
      emit("closeModal");
    };

    const rules = {
      denominacion: [
        {
          required: true,
          message: "El campo denominación no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ],
      abreviatura: [
        {
          required: true,
          message: "El campo abreviatura no puede estar vacío",
          trigger: "blur"
        },
        {
          max: 350,
          message: "La cantidad máxima de caracteres es de 350",
          trigger: "blur"
        }
      ]
    };

    const layout = {
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 15,
        offset: 1
      }
    };
    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      formRef,
      layout,
      rules
    };
  }
};
</script>
